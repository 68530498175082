<template>
  <div class="ad-grid" v-if="hasBanner">
    <swiper :options="swiperOption">
      <swiper-slide class="ad-grid-container" v-for="(item, index) in source" :key="index">
        <div class="ad-grid-800_200">
          <a :href="`${item.url}${item.anchor}`" :target="item.action" @click="$emit('click', item)">
            <img :src="item.img" :alt="item.alt" />
          </a>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

const swiperOption = {
  slidesPerView: 1.3, // 畫面只能 1.3 個 slider 的寬度
  centeredSlides: true, // 並且當前 slider 必須置中
  spaceBetween: 10,
  grabCursor: true,
  loop: true,
};

const props = {
  source: Array,
};

const components = {
  swiper,
  swiperSlide,
};

const computed = {
  hasBanner() {
    return this.source.length > 0;
  },
};

const data = function () {
  return {
    swiperOption,
  };
};

export default {
  name: 'ad-slider',
  props,
  components,
  computed,
  data,
};
</script>
