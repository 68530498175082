<template>
  <div class="card single text-center">
    <i class="icon icon-xxl icon-check-circle success"></i>
    <h3 class="spacing top cartstep3success">訂單確認送出</h3>
    <p class="spacing bottom sm">
      感謝您此次的訂購，您的訂單編號為
      <br />
      <b class="value">{{ order.id }}</b>
    </p>
    <p class="spacing bottom sm">
      請於
      <b class="notice fn-md spacing">{{ order.expireDate }}</b>
      <br />
      完成ATM轉帳程序，逾期訂單將自動失效
    </p>
    <div class="alert aler-ordersuccess">
      <p>
        <span class="spacing right">銀行代碼</span>
        <span><b class="value">國泰世華 (代碼：013)</b></span>
      </p>
      <p>
        <span class="spacing right">轉帳號碼</span>
        <span>
          <b class="value fn-lg">{{ order.account }}</b>
        </span>
      </p>
      <p class="text-muted fn-sm">(僅限此筆交易使用)</p>
    </div>
    <p class="spacing bottom">
      提醒您，如欲查詢出貨進度，請至
      <query-order-wording is-mobile></query-order-wording>
    </p>
    <p class="spacing bottom floatleft cartstep3info">
      如選擇「門市取貨」，商品到神腦門市後，系統將發訊息通知您前往門市取貨，請於七天內至門市完成取貨。
      <br />
    </p>
    <h5 class="spacing bottom md">再次感謝您的惠顧，謝謝！</h5>
    <!-- 繼續購物 | 訂單查詢 按鈕-->
    <continue-bar :is-mobile="true"></continue-bar>
  </div>
</template>

<script>
import continueBar from '@/components/common/continue-buy-button-group.vue';
import queryOrderWording from '@/components/common/query-order-wording.vue';

const components = {
  continueBar,
  queryOrderWording,
};

const props = {
  order: Object,
};

export default {
  name: 'atm',
  components,
  props,
};
</script>
