<template>
  <div class="container">
    <!-- header bar -->
    <header-cart>
      <template slot="header-left">
        <!-- much much來的不顯示 -->
        <a v-if="isNotFromMuchMuch" class="btn-link" href="javascript:void(0);" @click="goBackFromFlag">
          <i class="icon icon-home"></i>
        </a>
      </template>
      <template slot="header-middle">訂單確認送出</template>
      <template slot="header-right">
        <div></div>
      </template>
    </header-cart>
    <main class="offset bottom">
      <div>
        <step-icon-bar :step="3"></step-icon-bar>
      </div>
      <div class="card single text-center">
        <!-- 訂單狀況 -->
        <component
          :is="renderMatchedComponent(paymentType)"
          :order="order"
          :payment-method="paymentType"
          :is-mobile="true"></component>
        <!-- 防詐騙提示-->
        <avoid-fraud :is-mobile="true"></avoid-fraud>
      </div>
      <!-- AD much much來的皆不顯示 -->
      <ad-slider v-if="isNotFromMuchMuch" :source="banners" @click="selectPromotionHandler"></ad-slider>
    </main>
    <!-- hamiPay 綁定 Modal -->
    <modal-cht-bind v-if="isBindChtAccount"></modal-cht-bind>
  </div>
</template>

<script>
import { isNotFromMuchMuch } from '@/helpers/is-from-app';
import { goBackFromFlag } from '@/helpers/navigation-goback-where';
import { createPromotionItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { defaultOrder, defaultPaymentType } from '@/components/common/cart-finished-constant';
import { renderState } from '@/helpers/vuex';
import finishedMethods from '@/mixins/cart/finished/finished-methods';
import adSlider from '@/components/mobile/finished/ad-slider.vue';
import atm from '@/components/mobile/finished/atm.vue';
import authFail from '@/components/mobile/finished/auth-fail.vue';
import avoidFraud from '@/components/common/avoid-fraud.vue';
import ccr from '@/components/mobile/finished/ccr.vue';
import headerCart from '@/components/mobile/Header/header-cart.vue';
import stepIconBar from '@/components/mobile/step-icon-bar.vue';
import modalChtBind from '@/components/common/finished/modal-cht-bind.vue';
import others from '@/components/common/finished/others.vue';

const data = function () {
  return {
    order: defaultOrder,
    paymentType: defaultPaymentType,
  };
};

const components = {
  atm,
  ccr,
  authFail,
  headerCart,
  stepIconBar,
  modalChtBind,
  adSlider,
  avoidFraud,
  others,
};

const methods = {
  goBackFromFlag,
  renderMatchedComponent(paymentType) {
    switch (paymentType) {
      case 'atm':
        return atm;
      case 'auth-fail':
        return authFail;
      case 'ccr':
        return ccr;
      default:
        return others;
    }
  },
  selectPromotionHandler(banner) {
    const parameterForGA = createPromotionItem(banner);
    sendGA4sEvent('select_promotion', parameterForGA);
  },
};

const computed = {
  isNotFromMuchMuch,
  isBindChtAccount() {
    const bindChtAccount = renderState('OrderFinishInfo', 'bindChtAccount');
    return bindChtAccount.length > 0;
  },
  banners() {
    return this.$store.state.OrderFinishInfo.banners || [];
  },
};

export default {
  name: 'mobile-cart-finished',
  components,
  data,
  methods,
  computed,
  mixins: [finishedMethods],
  mounted() {
    this.$store.dispatch('Loading/updateLoading', { isLoading: false });
    if (isNotFromMuchMuch()) {
      this.$store.dispatch('OrderFinishInfo/fetchBanners', 'OB');
    }
  },
};
</script>

<style scoped>
.mobileWrapper {
  margin-top: 44px;
}
.cartstep3info {
  text-align: center;
}
</style>
