<template>
  <div class="step-wrap has-count">
    <ul class="step-list">
      <li :class="cssClass(1)">
        <span class="number">1</span>
        <span class="label">填寫配送資料</span>
      </li>
      <li :class="cssClass(2)">
        <span class="number">2</span>
        <span class="label">選擇付款方式</span>
      </li>
      <li :class="cssClass(3)">
        <span class="number">3</span>
        <span class="label">訂單送出</span>
      </li>
    </ul>
  </div>
</template>

<script>
const props = {
  step: Number,
};

const methods = {
  cssClass(step) {
    return {
      stepItem: true,
      active: this.step >= step,
    };
  },
};

export default {
  name: 'step-icon-bar',
  props,
  methods,
};
</script>
