<template>
  <div>
    <i class="icon icon-xxl icon-check-circle success"></i>
    <h3 class="spacing top cartstep3success">訂單確認送出</h3>
    <p class="spacing bottom sm">
      感謝您此次的訂購，系統已發出訂單確認通知信到您的Email信箱，您的訂單編號為
      <br />
      <b class="value fn-md">{{ order.id }}</b>
    </p>
    <p class="spacing bottom sm">
      請於
      <b class="notice fn-md spacing">{{ order.expireDate }}</b>
      <br />
      至各神腦特約服務中心完成繳費，逾期訂單將自動失效
    </p>
    <div class="alert aler-ordersuccess">
      <p><b>請出示『繳費條碼』供門市刷讀</b></p>
      <p>
        <img :src="order.qrCodeUrl" class="scan-qrcode" />
      </p>
      <p class="text-muted fn-sm">
        繳費金額：
        <b class="fn-md">{{ order.amount | dollar }}元</b>
      </p>
    </div>
    <p class="spacing bottom">
      訂購完成後可於「
      <a v-if="!isFromMuchMuch" :href="orderDetailLink" class="inverted underline"> 訂單查詢 > 訂單明細 </a>
      <b v-else>much much APP-訂單查詢</b>
      」點選顯示繳費條碼，並至神腦特約服務中心出示繳費條碼供門市人員刷讀
      <!-- 下載much much app -->
      <a
        v-if="isFromMuchMuch"
        href="https://apis.senao.com.tw/splus2r/appinstall?openExternalBrowser=1"
        target="_blank"
        style="margin-top: 16px">
        <img
          src="/images/edm-banner.jpg"
          alt="下載 much much APP"
          style="margin: 0; padding: 0; line-height: 0; border: 0; width: 100%; max-width: 500px" />
      </a>
    </p>
    <p class="spacing bottom floatleft cartstep3info">
      其他提醒事項：
      <br />
      1.神腦特約服務中心繳費，付款方式可選擇現金、信用卡或行動支付工具，實際收款方式依門市現場說明為主。
      <br />
      2.神腦特約服務中心繳費完成後，若要取消訂單，需待訂單狀態為『已取消』方能協助退款。
      <br />
      3.發票將依{{ fromWhereTxt }}訂購流程為主，門市無法立即取得發票。
      <br />
    </p>
    <h5 class="spacing bottom md">再次感謝您的惠顧，謝謝！</h5>
    <!-- 繼續購物 | 訂單查詢 | 手機安心保 按鈕-->
    <continue-bar :is-mobile="true"></continue-bar>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { isFromMuchMuch } from '@/helpers/is-from-app';
import continueBar from '@/components/common/continue-buy-button-group.vue';

const components = {
  continueBar,
};

const filters = {
  dollar,
};

const props = {
  order: Object,
};

const methods = {
  orderDetailLink() {
    return `${process.env.VUE_APP_ONLINE}/m/order/Detail/${this.order.id}`;
  },
};

const data = () => ({
  fromWhereTxt: '神腦生活',
});

export default {
  name: 'ccr',
  components,
  data,
  computed: {
    isFromMuchMuch,
  },
  props,
  filters,
  methods,
};
</script>
