<template>
  <div>
    <div class="card single text-center">
      <img class="img-fail-doll" src="/images/icons/img-fail-figure@3x.png" alt="付款失敗" />
      <h3 class="notice spacing top">付款失敗，請重選付款方式</h3>
      <p class="text-muted text-left spacing bottom">
        親愛的客戶，很抱歉，此筆訂單訂購未成功。
        <br />
        可能原因為資料輸入錯誤、額度或神腦幣不足、銀行授權網路壅塞，導致未收到銀行授權資訊。
      </p>
      <ul class="has-type-disc has-inline text-left">
        <li><b>建議方式：</b></li>
        <li>回購物車並檢查信用卡資料</li>
        <li>與原信用卡發卡客服中心聯繫 (VISA金融卡無法分期)</li>
        <li>換其他信用卡訂購</li>
        <li>選擇其他付款方式</li>
      </ul>
      <!-- 回購物車 | 訂單查詢 按鈕 -->
      <back-to-cart-button-group :is-mobile="true"></back-to-cart-button-group>
    </div>
  </div>
</template>

<script>
import backToCartButtonGroup from '@/components/common/back-to-cart-button-group.vue';

const components = {
  backToCartButtonGroup,
};

export default {
  name: 'auth-fail',
  components,
};
</script>
